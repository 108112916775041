<template>
  <span
    class="trustpilot-widget"
    :data-locale="dataLocale"
    :data-template-id="dataTemplateId"
    :data-businessunit-id="dataBusinessUnitId"
    :data-style-height="dataStyleHeight"
    :data-style-width="dataStyleWidth"
    :data-theme="dataTheme"
  >
    <a :href="url" target="_blank"> Trustpilot </a>
  </span>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    dataLocale: {
      type: String,
      default: 'en-GB',
    },
    dataTemplateId: {
      type: String,
      default: '',
    },
    dataBusinessUnitId: {
      type: String,
      default: '',
    },
    dataStyleHeight: {
      type: String,
      default: '0px',
    },
    dataStyleWidth: {
      type: String,
      default: '0%',
    },
    dataTheme: {
      type: String,
      default: 'light',
    },
    url: {
      type: String,
      default: '',
    },
  },
  created() {
    const trustpilotScript = document.createElement('script');
    trustpilotScript.setAttribute(
      'src',
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
    );
    trustpilotScript.setAttribute('async', 'async');
    document.head.appendChild(trustpilotScript);
  },
});
</script>

<style scoped>
img.displayed {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 307px;
}
</style>
